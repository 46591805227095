// import { getCookie } from ".";
declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		digitalData: ProcessDigitalData;
		_satellite: {
			pageBottom: Function;
			track: Function;
		};
	}
}

export type ProcessDigitalData = {
	destinationURL?: string;
	cleanUrl?: string;
	pageName?: string;
	pageType?: string;
	intcmp?: string;
	cmp?: string;
	nameFaq?: string;
	label?: string;
	downloadname?: string;
};

export const processDigitalData = (data: ProcessDigitalData): void => {
	const {
		destinationURL,
		cleanUrl,
		pageName,
		pageType,
		intcmp = "",
		cmp = "",
		nameFaq,
		label,
		downloadname
	} = data;

	const type = pageName ? "page" : "eventProperties";
	const corporateType = pageName ? "page-corporate" : "event-corporate";
	const externalID = false; //getCookie("ext"); // DROP 3
	// why externalID to false? if false then the below code should be commented and status should be set to non loggato directly.
	// const status = externalID ? "loggato" : "non loggato";
	const status = "non loggato";

	const allDigitalData = {
		eventProperties: {
			pageEvent: {
				destinationURL,
				cleanUrl,
				nameFaq,
				label,
				downloadname
			},
			userInfo: {
				externalID,
				status
			},
			otherInfo: {
				externalID
			}
		},

		page: {
			pageInfo: {
				pageName,
				pageType, //:"page",
				destinationURL, //:"[URL COMPLETA]",
				cleanUrl, //:"[URL SENZA PARAMETRI]",
				intcmp, //:"[PARAMETRO INTCMP]",
				cmp //:"[PARAMETRO CMP]",
			},
			category: {
				primaryCategory: "corporate",
				subCategory1: "digital signature", // 3° campo pagename
				subCategory2: "" // 4 campo pagename
			},
			userInfo: {
				externalID,
				status
			}
		}
	};

	const digitalData = { [type]: allDigitalData[type] };

	/* istanbul ignore else */
	if (typeof window !== "undefined" && !!window["_satellite"]) {
		const _satellite = window["_satellite"];
		_satellite.track(corporateType);
		window["digitalData"] = digitalData;
	}
};
