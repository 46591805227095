// Styles
import "./Acceptance.scss";

// Modules & hooks
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { setCurrentIndex } from "app/store/privacyConsents/privacyConsentsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { selectCurrentStep, selectSteps } from "app/store/metro/metroSlice";

// Components
import Panel from "app/components/panel/Panel";
import Button from "app/components/_shared/atoms/Button/Button";

// Utils
import { scrollToEl } from "utils";
import { processDigitalData } from "utils/analytics";

// External Types
import { RecapPageGeneralConditionItem } from "@sky-uk/ita-api-quotations-sdk/model/recapPageGeneralConditionItem";

// Types
export type AcceptanceProps = {
	currentIndex: number;
	index: number;
	isFreezed: boolean;
	iconButton?: boolean;
	navigateOnLastCta?: boolean;
	isLastAcceptance?: boolean;
	consentIndex?: number;
} & RecapPageGeneralConditionItem;

// Element
const Acceptance = ({
	title,
	description,
	links,
	cta,
	index,
	currentIndex,
	isFreezed,
	iconButton,
	navigateOnLastCta,
	isLastAcceptance,
	consentIndex
}: AcceptanceProps): JSX.Element => {
	const [accepted, setAccepted] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const steps = useAppSelector(selectSteps);
	const currentStep = useAppSelector(selectCurrentStep);
	const { search } = useLocation();
	const disabled = currentIndex >= index || isFreezed;
	const acceptanceClicked = currentIndex >= index && accepted;
	const pathName = window.location.pathname;

	useEffect(() => {
		/* istanbul ignore else */
		if (currentIndex > index) {
			setAccepted(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAcceptanceClick = (acceptanceId: number, ctaLabel: string | undefined): void => {
		processDigitalData({
			label: `digital signature – ${title?.toLowerCase() || ""} – ${ctaLabel?.toLowerCase()}`,
			destinationURL: window.location.href,
			cleanUrl: window.location.origin + pathName
		});

		if (!accepted) {
			const acceptances = document.getElementsByClassName("acceptance");
			const titles = document.getElementsByClassName("consents__title");

			if (navigateOnLastCta) {
				navigate(`${steps[currentStep.index].pageName}${search}`);
			} else {
				dispatch(setCurrentIndex(acceptanceId + 1));
				if (acceptanceId + 1 <= acceptances.length) {
					if (isLastAcceptance && typeof consentIndex == "number") {
						scrollToEl(titles[consentIndex + 1].id, "id");
					} else {
						scrollToEl(acceptances[acceptanceId].id, "id");
					}
				} else {
					scrollToEl(".privacy", "cls");
				}
				setAccepted(!accepted);
			}
		}
	};

	return (
		<div id={`cga-id-${index}`} className={`acceptance ${disabled && "unlocked"}`}>
			<Panel title={title} __className="acceptance__panel">
				{description && (
					<div
						className="acceptance__panel--text"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
				{links?.map((link, idx) => (
					<a
						key={idx}
						href={link.url}
						target={link.blank ? "_blank" : "_self"}
						rel="noreferrer"
						className="acceptance__panel--link medium"
						onClick={() =>
							processDigitalData({
								downloadname: link.label,
								destinationURL: window.location.href,
								cleanUrl: window.location.origin + pathName
							})
						}
					>
						{link.label}
					</a>
				))}
			</Panel>
			{!isFreezed && (
				<Button
					{...{
						additionalClassName: `acceptance__button ${
							acceptanceClicked ? "green" : ""
						}`,
						onClick: () => handleAcceptanceClick(index, cta?.ctaLabel),
						...(iconButton && { icon: accepted ? "Tick" : "ChevronDown" })
					}}
				>
					{acceptanceClicked ? cta?.ctaClickedLabel : cta?.ctaLabel}
				</Button>
			)}
		</div>
	);
};

export default Acceptance;
