import { useEffect } from "react";

const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: () => void): void => {
	const handleClick = (ev: MouseEvent): void => {
		/* istanbul ignore else */
		if (ref.current && !ref.current.contains(ev.target as never)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return (): void => document.removeEventListener("mousedown", handleClick);
	});
};

export default useOutsideClick;
