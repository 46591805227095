// Styles
import "./costsRecap.scss";

// External Types
import { RecapPageOfferCostsSection } from "@sky-uk/ita-api-quotations-sdk/model/recapPageOfferCostsSection";

// Component
import IconSwitcher from "../_shared/atoms/Icon/Icon";
import { priceLabelMapper } from "../costs/Costs";

// Types
export type OneShotOrRecurringOrFinancial = "one-shot" | "recurring" | "financial";
export type CostsRecapProps = {
	offerCosts: RecapPageOfferCostsSection;
	type: OneShotOrRecurringOrFinancial;
};

export const showRecapFinalPrice = (
	finalPrice?: number,
	type?: string,
	costTotalIsPrepaid?: boolean,
	listPrice?: number
): string => {
	if ("recurring" === type && costTotalIsPrepaid) {
		return "PREPAGATO";
	}

	if (finalPrice === 0 && listPrice === 0) {
		return "INCLUSO";
	}

	return `${finalPrice?.toFixed(2).replace(".", ",")}€`;
};

export const CostsRecap = (props: CostsRecapProps): JSX.Element => {
	const { type, offerCosts } = props;

	return (
		<div>
			<div className={`costRecap ${priceLabelMapper[type] || "recurring"}`}>
				<div className="costRecap__details">
					<span className="costRecap__label medium">
						{offerCosts?.costsTotalSummaryLabel}
					</span>
				</div>
				<span className="costRecap__price medium">
					{showRecapFinalPrice(
						offerCosts?.costsTotalFinalPrice,
						type,
						offerCosts?.costsTotalIsPrepaid,
						offerCosts?.costsTotalListPrice
					)}
				</span>
			</div>

			{offerCosts?.costsTotalSummaryDetailsLabel && (
				<div className="costRecap__summaryDetailsLabel">
					{offerCosts?.costsTotalSummaryDetailsLabel}
				</div>
			)}

			{offerCosts?.discountLabel && (
				<div className="costRecap__discount-margin-bottom">
					<div className="costRecap__discount-label">
						<div className="costRecap__icon-tag">
							<IconSwitcher icon="IconTag" fill={"#F15A22"} className="rotate-svg" />
						</div>
						{offerCosts?.discountLabel}
					</div>
				</div>
			)}
		</div>
	);
};

export default CostsRecap;
