// Styles
import "./Header.scss";

// Types
export type HeaderProps = {
	expiryWarning?: string | false;
};

// Element
const Header = ({ expiryWarning }: HeaderProps): JSX.Element => {
	return (
		<header className="header">
			<img
				className="sky-logo"
				src="https://images.contentstack.io/v3/assets/blta6c52b9cd5519be3/blt359ac09d856638c8/5f6853c7d70e764f32921c7c/Logo_spectrum.png"
				alt="sky_logo"
			/>
			{!!expiryWarning && <p dangerouslySetInnerHTML={{ __html: expiryWarning }}></p>}
		</header>
	);
};

export default Header;
