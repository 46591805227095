// Style
import "./Recap.scss";

// Hooks & modules
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// Components
import Panel from "app/components/panel/Panel";
import PersonalData from "app/components/personal-data/PersonalData";
import Payment from "app/components/payment/Payment";
import Button from "app/components/_shared/atoms/Button/Button";
import IconSwitcher from "app/components/_shared/atoms/Icon/Icon";

// Utils
import { scrollToEl } from "utils/index";
import { processDigitalData } from "utils/analytics";

// Redux
import { setCurrentStep, selectSteps } from "app/store/metro/metroSlice";
import { selectIsQuotationFreezed } from "app/store/recap/recapSlice";

// Types
import { Step } from "app/components/metro/Metro";
import { RecapPageProps } from "./recap.types";
import OffersList from "../../components/offers-list/OffersList";
import { RecapPageHeadingSection } from "@sky-uk/ita-api-quotations-sdk";

// Helper
const getRecapHeading = (data: RecapPageHeadingSection): JSX.Element => {
	return (
		<>
			<section className="recap-heading-wrp">
				{data.headingText && (
					<h1
						className="sky-display-standard medium"
						dangerouslySetInnerHTML={{ __html: data.headingText }}
					/>
				)}
				{data.subheadingText && (
					<div className="recap-heading-informative sky-desk">
						<p
							className="recap-heading-informative--text"
							dangerouslySetInnerHTML={{ __html: data.subheadingText }}
						/>
					</div>
				)}
			</section>
			{data.subheadingText && (
				<div className="recap-heading-informative sky-mob">
					<p
						className="recap-heading-informative--text"
						dangerouslySetInnerHTML={{ __html: data.subheadingText }}
					/>
				</div>
			)}
		</>
	);
};

// Element
const RecapPage = ({ dataView }: RecapPageProps): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { search } = useLocation();
	const isFreezed = useAppSelector(selectIsQuotationFreezed);
	const steps = useAppSelector(selectSteps);
	const currentStep = steps.find(({ pageName }) => pageName === "/sign") as Step;
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const intcmp =
			searchParams.get("intcmp") ||
			/* istanbul ignore next: this is a fallback always true */ "";
		const cmp =
			searchParams.get("cmp") ||
			/* istanbul ignore next: this is a fallback always true */ "";

		processDigitalData({
			pageName: dataView?.firstStepPageCorporateConfiguration?.pageCorporateLabel,
			pageType: "page",
			destinationURL: window.location.href,
			cleanUrl: window.location.origin + "/sign",
			intcmp,
			cmp
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataView]);

	useEffect(() => {
		steps.length && dispatch(setCurrentStep(currentStep));
	}, [dispatch, steps, currentStep]);

	/* istanbul ignore else */
	if (!dataView) {
		return null;
	}

	return (
		<div className="recapPage">
			{dataView.heading && getRecapHeading(dataView.heading)}
			<div className="recapPage__anchor">
				<IconSwitcher
					onClick={() => scrollToEl(".panel", "cls")}
					className="recapPage__anchor--icon"
					icon="ChevronDown"
					fill="#4D4D4D"
				/>
			</div>
			<div className="recapPage__content">
				{!!dataView?.offerSection && Object.keys(dataView?.offerSection).length !== 0 && (
					<Panel
						title={dataView.offerSection.title}
						subtitle={dataView.offerSection.subtitle}
						isOfferSection={true}
					>
						<OffersList {...dataView.offerSection} />
					</Panel>
				)}
				{!!dataView?.personalDataSection &&
					Object.keys(dataView?.personalDataSection).length !== 0 && (
						<Panel
							title={dataView.personalDataSection.title}
							subtitle={dataView.personalDataSection.subtitle}
						>
							<PersonalData {...dataView.personalDataSection} />
						</Panel>
					)}
				{!!dataView?.paymentMethodsSection &&
					Object.keys(dataView?.paymentMethodsSection).length !== 0 && (
						<div>
							<Panel
								title={dataView.paymentMethodsSection.title}
								subtitle={dataView.paymentMethodsSection.subtitle}
								paymentMethodsSection={true}
							>
								<Payment {...dataView.paymentMethodsSection} />
							</Panel>

							{!!dataView?.paymentMethodsSection?.paymentLabels?.firstLine &&
								!!dataView?.paymentMethodsSection?.paymentLabels?.secondLine && (
									<section className="paymentLabels__wrp flex-wrp">
										<IconSwitcher
											className="paymentLabels__info"
											icon="InfoCircle"
										/>
										<div className="paymentLabels__contentWrp">
											<p className="paymentLabels__title medium">
												{
													dataView.paymentMethodsSection.paymentLabels
														.firstLine
												}
											</p>
											<p className="paymentLabels__description">
												{
													dataView.paymentMethodsSection.paymentLabels
														.secondLine
												}
											</p>
										</div>
									</section>
								)}
						</div>
					)}
				{!isFreezed && dataView?.goToSecondStepCta?.ctaLabel && (
					<div className="recapPage__buttonWrp">
						<Button
							onClick={() =>
								navigate(`${steps[currentStep.index].pageName}${search}`)
							}
						>
							{dataView?.goToSecondStepCta?.ctaLabel}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default RecapPage;
