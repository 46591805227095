/* istanbul ignore file */
import { configureStore, ThunkAction, Action, EnhancedStore } from "@reduxjs/toolkit";
import { recapSlice } from "app/store/recap/recapSlice";
import { metroSlice } from "app/store/metro/metroSlice";
import { drawerSlice } from "app/store/drawer/drawer";
import { errorSlice } from "app/store/error/errorSlice";
import { privacyConsentsSlice } from "app/store/privacyConsents/privacyConsentsSlice";
import { recapApi } from "app/pages/recap/services/recap.service";
import tokenManagerApi from "app/pages/recap/services/token-manager-service.service";

function makeStore(): EnhancedStore {
	return configureStore({
		reducer: {
			[recapApi.reducerPath]: recapApi.reducer,
			[tokenManagerApi.reducerPath]: tokenManagerApi.reducer,
			recap: recapSlice.reducer,
			metro: metroSlice.reducer,
			drawer: drawerSlice.reducer,
			error: errorSlice.reducer,
			privacyConsents: privacyConsentsSlice.reducer
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat([recapApi.middleware, tokenManagerApi.middleware])
	});
}
export const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
