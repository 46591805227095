// Style
import "./CGA.scss";

// Hooks & modules
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import useRedirectHome from "app/hooks/useRedirectHome";

// Components
import Consents from "app/components/consents/Consents";
import Privacy from "app/components/privacy/Privacy";

// Redux
import { setCurrentStep, selectSteps } from "app/store/metro/metroSlice";
import { selectIsQuotationFreezed } from "app/store/recap/recapSlice";
import { selectCurrentIndex } from "app/store/privacyConsents/privacyConsentsSlice";

// Utils
import { processDigitalData } from "utils/analytics";

// Types
import { Step } from "app/components/metro/Metro";
import { QuotationView } from "@sky-uk/ita-api-quotations-sdk";

export type CGAPageProps = {
	dataView: QuotationView;
};

// Element
const CGAPage = ({ dataView }: CGAPageProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const currentIndex = useAppSelector<number>(selectCurrentIndex);
	const isFreezed = useAppSelector<boolean>(selectIsQuotationFreezed);
	const acceptances = document.getElementsByClassName("acceptance");
	const steps = useAppSelector(selectSteps);
	const pathName = window.location.pathname;
	const currentStep = steps.find(({ pageName }) => pageName === pathName) as Step;
	let counter = 0;

	useRedirectHome(dataView);

	useEffect(() => {
		processDigitalData({
			pageName: dataView?.secondStepPageCorporateConfiguration?.pageCorporateLabel,
			pageType: "page",
			destinationURL: window.location.href,
			cleanUrl: window.location.origin + pathName
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataView]);

	useEffect(() => {
		steps.length && dispatch(setCurrentStep(currentStep));
	}, [dispatch, steps, currentStep]);

	const { privacySection, generalConsentsSections } =
		dataView || /* istanbul ignore next: this is a fallback always true */ {};

	const emptyPrivacySection = privacySection && Object.keys(privacySection).length === 0;

	const consentsItems =
		!!generalConsentsSections?.length &&
		generalConsentsSections?.map((consent) => consent.items?.length);
	const finalAcceptanceIndex = !!consentsItems && consentsItems.reduce((a, b) => a && b && a + b);

	return (
		<div className="cga">
			{!!generalConsentsSections?.length &&
				finalAcceptanceIndex &&
				generalConsentsSections?.map((consents, idx) => {
					let consent = (
						<Consents
							key={"cga_" + idx}
							consentIndex={idx}
							navigateOnLastCta={emptyPrivacySection}
							finalAcceptanceIndex={finalAcceptanceIndex}
							currentIndex={currentIndex}
							startIndex={counter}
							isFreezed={isFreezed}
							iconButton
							{...consents}
						/>
					);
					counter +=
						consents.items?.length ||
						/* istanbul ignore next: this is a fallback always true */ 0;
					return consent;
				})}
			{!emptyPrivacySection && (
				<Privacy
					currentIndex={currentIndex}
					isFreezed={isFreezed}
					acceptances={acceptances}
					title={privacySection?.title}
					cta={privacySection?.cta}
				/>
			)}
		</div>
	);
};

export default CGAPage;
