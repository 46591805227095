export type IconProps = {
	fill?: string;
	stroke?: string;
};

export type IconSwitcherProps = {
	icon:
		| "InfoCircle"
		| "ChevronDown"
		| "Minus"
		| "Tick"
		| "Error"
		| "Phone"
		| "TickCircle"
		| "IconTag"
		| "CloseCircle";
	className?: string;
	fill?: string;
	stroke?: string;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const ChevronDown = ({ fill = "#FFFFFF" }: IconProps): JSX.Element => (
	<svg width={30} viewBox="0 0 43 25" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.826 19.504 3.071.75c-.077.06-.163.127-.26.206C2.35 1.343.876 2.475.876 3.323c.017.37.165.688.467.993L20.587 23.57a1.75 1.75 0 0 0 2.477 0L42.309 4.316c.304-.305.452-.622.465-.993 0-.848-1.47-1.98-1.937-2.367a9.012 9.012 0 0 0-.255-.206L21.826 19.504Z"
			fill={fill}
			fillRule="evenodd"
		/>
	</svg>
);

export const IconTag = ({ fill = "#F15A22" }: IconProps): JSX.Element => (
	<svg
		version="1.1"
		id="c76f5799-6153-479d-90da-9d9afd31b2c6"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 24 24"
		style={{ transform: `rotate(90deg)` }}
	>
		<path
			fill={fill}
			className=""
			d="M6.87,9.6L6.87,9.6C6.4,9.13,6.14,8.5,6.14,7.83c0-1.38,1.11-2.5,2.5-2.5c1.38,0,2.5,1.11,2.5,2.5
	s-1.11,2.5-2.5,2.5C7.98,10.33,7.34,10.07,6.87,9.6z M8.05,7.25C7.72,7.57,7.73,8.1,8.05,8.42l0,0c0.15,0.15,0.36,0.24,0.58,0.24
	c0.46,0,0.83-0.37,0.84-0.82C9.47,7.38,9.1,7,8.64,7C8.42,7,8.2,7.09,8.05,7.25z"
		/>
		<path
			fill={fill}
			className=""
			d="M1.97,4.43v5.91c-0.07,0.69,0.2,1.37,0.73,1.81l9.39,9.39c0.65,0.65,1.71,0.65,2.36,0l7.07-7.07
	c0.65-0.65,0.65-1.71,0-2.36l-9.34-9.38c-0.51-0.55-1.25-0.83-2-0.73H4.47C3.18,2.13,2.14,3.14,1.97,4.43z M10.32,3.67
	c0.23-0.03,0.47,0.04,0.65,0.19l9.43,9.39l-7.12,7.07l-9.44-9.33c-0.17-0.17-0.26-0.41-0.24-0.65V4.5c0.07-0.44,0.43-0.78,0.87-0.83
	L10.32,3.67z"
		/>
	</svg>
);

export const InfoCircle = ({ fill = "#000FF5" }: IconProps): JSX.Element => (
	<svg viewBox="0 0 61 60" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M.5 30c0-16.568 13.432-30 30-30 16.568 0 30 13.432 30 30 0 16.568-13.432 30-30 30-16.568 0-30-13.432-30-30Zm56.25 0c0-14.475-11.775-26.25-26.25-26.25S4.25 15.525 4.25 30 16.025 56.25 30.5 56.25 56.75 44.475 56.75 30Zm-26.255-6.811c-1.203 0-2.222.361-2.222 1.743v22.915l.079.01c1.525.22 4.31.62 4.31-1.606V23.337a14.984 14.984 0 0 0-2.167-.148Zm.026-5.083a3.281 3.281 0 1 0 0-6.562 3.281 3.281 0 0 0 0 6.562Z"
			fill={fill}
			fillRule="evenodd"
		/>
	</svg>
);

export const Error = ({ fill }: IconProps): JSX.Element => (
	<svg viewBox="0 0 49 48" xmlns="http://www.w3.org/2000/svg">
		<g id="NEW" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="4.2-Digital-Signature_DSK_Pagina-Scaduta"
				transform="translate(-694.000000, -136.000000)"
				fill={fill ?? "#B72500"}
			>
				<g
					id="Icon/Sky-Set/Linear/System/Tick/tick-circle"
					transform="translate(694.500000, 136.000000)"
				>
					<path
						d="M24,0 C10.746,0 0,10.7445 0,24 C0,37.2555001 10.746,48 24,48 C37.2540001,48 48,37.2555001 48,24 C48,10.7445 37.2540001,0 24,0 Z M24,45 C12.42,45 3.00000001,35.5800001 3.00000001,24 C3.00000001,12.42 12.42,3.00000001 24,3.00000001 C35.5800001,3.00000001 45,12.42 45,24 C45,35.5800001 35.5800001,45 24,45 Z M36.0301501,14.091 L26.1211501,24.0000001 L35.3131501,33.1920001 C35.5621501,33.4410001 35.6836501,33.7320001 35.6716501,34.0650001 C35.6476501,34.7670001 34.5541501,35.7570001 33.9091501,36.0315001 L24.0001501,26.1210001 L14.80815,35.3130001 C14.55915,35.5620001 14.26815,35.6820001 13.93515,35.6715001 C13.23315,35.6475001 12.24315,34.5540001 11.96865,33.9090001 L21.8791501,24.0000001 L12.68715,14.808 C12.43665,14.559 12.31815,14.268 12.32865,13.9365 C12.35265,13.233 13.44615,12.243 14.09115,11.9685 L24.0001501,21.8790001 L33.1921501,12.687 C33.4411501,12.438 33.7336501,12.318 34.0636501,12.3285 C34.7671501,12.3525 35.7571501,13.446 36.0301501,14.091 Z"
						id="Shape"
					></path>
				</g>
			</g>
		</g>
	</svg>
);

export const Tick = ({ fill = "#FFFFFF" }: IconProps): JSX.Element => (
	<svg viewBox="0 0 44 32" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M42.273.429c-.257-.21-.474-.351-.653-.429L15.402 26.513 3.063 14.037c-.178.077-.396.22-.653.426-.88.711-2.227 2.027-1.113 3.15l14.105 14.262L43.387 3.576c1.113-1.124-.23-2.436-1.114-3.147Z"
			fill={fill}
			fillRule="evenodd"
		/>
	</svg>
);

export const Minus = ({ fill = "#FFFFFF" }: IconProps): JSX.Element => (
	<svg viewBox="0 0 35 4" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M33.872 3.75c.328-.812.42-2.653-.18-3.296C33.409.152 33.045 0 32.602 0H.884c-.328.812-.418 2.653.18 3.296.283.302.647.454 1.09.454h31.719Z"
			fill={fill}
			fillRule="evenodd"
		/>
	</svg>
);

export const Phone = ({ fill }: IconProps): JSX.Element => (
	<svg viewBox="0 0 57 58" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<linearGradient id="skyGradient">
				<stop offset="0%" stopColor="#FF9E00" />
				<stop offset="30.93%" stopColor="#FF0000" />
				<stop offset="61.67%" stopColor="#B5007D" />
				<stop offset="88.09%" stopColor="#21429C" />
				<stop offset="100%" stopColor="#0071FF" />
			</linearGradient>
		</defs>
		<path
			fill={fill ?? "url('#skyGradient')"}
			d="M53.434 43.111c3.834 4.192 3.89 5.28 1.873 8.237-3.683 5.396-10.046 7.745-19.757 3.829-6.004-2.421-12.084-6.696-19.309-13.919C9.019 34.035 4.746 27.953 2.323 21.95-1.594 12.239.758 5.875 6.153 2.193 9.112.177 10.199.231 14.392 4.065c3.967 3.628 7.728 8.803 9.328 11.966 1.378 2.72.943 3.876-.212 5.233-.823.964-1.916 2.117-2.974 3.175-1.087 1.087-1.155 1.483-.137 2.979 1.02 1.496 2.976 3.881 4.39 5.295 1.413 1.414 3.798 3.37 5.295 4.39 1.496 1.018 1.893.952 2.98-.136 1.058-1.057 2.21-2.152 3.175-2.973 1.358-1.155 2.51-1.59 5.233-.214 3.162 1.601 8.337 5.362 11.965 9.33Zm-34.547-4.498c6.641 6.643 12.107 10.584 18.062 13.095 8.657 3.647 13.498.958 15.866-3.43-3.336-4.794-9.823-9.695-13.993-11.568 0 0-2.012 1.8-3.116 2.902-3.083 3.083-5.672 1.806-7.731.583-2.08-1.235-4.273-3.275-5.833-4.835-1.562-1.562-3.602-3.756-4.838-5.837-1.222-2.057-2.499-4.648.583-7.73 1.105-1.103 2.903-3.113 2.903-3.113-1.873-4.17-6.772-10.658-11.569-13.995-4.386 2.37-7.076 7.21-3.429 15.866 2.51 5.955 6.454 11.42 13.095 18.062Z"
			fillRule="evenodd"
		/>
	</svg>
);

export const TickCircle = ({ fill = "#2FA841" }: IconProps): JSX.Element => (
	<svg
		width="48px"
		height="48px"
		viewBox="0 0 61 60"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="Sky-Design-System" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
			<g
				id="00-DSM/04-Iconography"
				transform="translate(-223.000000, -1499.000000)"
				fill={fill}
			>
				<g id="Group" transform="translate(116.000000, 464.000000)">
					<g
						id="Icon/Sky-Set/Linear/System/Tick/tick-circle"
						transform="translate(107.500000, 1035.000000)"
					>
						<path
							d="M30,0 C13.4325,0 0,13.430625 0,30 C0,46.5693751 13.4325,60 30,60 C46.5675001,60 60,46.5693751 60,30 C60,13.430625 46.5675001,0 30,0 Z M30.0000001,3.75000001 C44.4750001,3.75000001 56.25,15.525 56.25,30.0000001 C56.25,44.4750001 44.4750001,56.25 30.0000001,56.25 C15.525,56.25 3.75000001,44.4750001 3.75000001,30.0000001 C3.75000001,15.525 15.525,3.75000001 30.0000001,3.75000001 Z M45.9230626,16.8759376 C46.1030626,16.9528126 46.3186876,17.0953126 46.5755626,17.2996876 C47.4586876,18.0028126 48.8030627,19.3021876 47.6893126,20.4121876 L24.9061876,43.1971876 C24.1036876,43.9978126 22.8043126,43.9978126 22.0036876,43.1971876 L11.2243125,32.4178126 C10.1105625,31.3078126 11.4568125,30.0065626 12.3380625,29.3034376 C12.5949375,29.0990626 12.8124375,28.9584376 12.9905625,28.8815626 L23.4549376,39.3440626 L45.9230626,16.8759376 Z"
							id="Shape"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const CloseCircle = ({ fill = "#000FF5" }: IconProps): JSX.Element => (
	<svg viewBox="0 0 61 60" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M30.5 0C13.932 0 .5 13.43.5 30s13.432 30 30 30c16.568 0 30-13.43 30-30S47.068 0 30.5 0Zm0 56.25C16.025 56.25 4.25 44.475 4.25 30S16.025 3.75 30.5 3.75 56.75 15.525 56.75 30 44.975 56.25 30.5 56.25Zm15.038-38.636L33.15 30l11.49 11.49c.312.311.464.675.449 1.091-.03.878-1.397 2.115-2.204 2.458L30.5 32.651l-11.49 11.49c-.311.312-.675.462-1.091.448-.878-.03-2.115-1.396-2.458-2.203L27.849 30l-11.49-11.49c-.313-.311-.461-.675-.448-1.09.03-.879 1.397-2.116 2.203-2.46L30.5 27.35l11.49-11.49c.311-.312.677-.461 1.09-.448.879.03 2.116 1.397 2.458 2.203Z"
			fill={fill}
			fillRule="evenodd"
		/>
	</svg>
);

export const ICON = {
	InfoCircle: InfoCircle,
	ChevronDown: ChevronDown,
	Minus: Minus,
	Tick: Tick,
	Error: Error,
	Phone: Phone,
	TickCircle: TickCircle,
	CloseCircle: CloseCircle,
	IconTag: IconTag
};

const IconSwitcher = ({
	icon,
	className,
	onClick,
	fill,
	stroke
}: IconSwitcherProps): JSX.Element => {
	const Icon = ICON[icon];

	return (
		<i className={className} onClick={onClick} data-testid="atoms-icon">
			<Icon fill={fill} stroke={stroke} />
		</i>
	);
};

export default IconSwitcher;
