// Styles
import "./Costs.scss";

// External Types
import { RecapPageComponentLine, RecapPageCostLine } from "@sky-uk/ita-api-quotations-sdk";

//Types
export type OneShotOrRecurringOrFinancial = "one-shot" | "recurring" | "financial";
export type CostsProps = {
	costs: RecapPageComponentLine;
	type: OneShotOrRecurringOrFinancial;
};
export const showProductFinalPriceComponent = (
	type: string,
	costLine: RecapPageComponentLine
): string => {
	return showFinalPrice(
		type,
		"partialFinalPrice",
		"partialListPrice",
		"partialIsPrepaid",
		costLine,
		false
	);
};

export const showProductFinalPrice = (
	type: string,
	costLine: RecapPageComponentLine | RecapPageCostLine
): string => {
	return showFinalPrice(type, "finalPrice", "listPrice", "isPrepaid", costLine, true);
};

const showFinalPrice = (
	type: string,
	finalPrice: "finalPrice" | "partialFinalPrice",
	listPrice: "listPrice" | "partialListPrice",
	isPrepaid: "isPrepaid" | "partialIsPrepaid",
	costLine: RecapPageComponentLine | RecapPageCostLine,
	totalPartial: boolean
): string => {
	const isRecurring = type === "recurring";
	const currentFinalPrice = finalPrice as never;

	if (isRecurring && costLine[isPrepaid as never]) {
		return "PREPAGATO";
	}

	if (
		costLine?.[currentFinalPrice] !== undefined &&
		totalPartial &&
		costLine?.[currentFinalPrice] === 0 &&
		costLine?.[listPrice as never] === 0
	) {
		return "INCLUSO";
	}

	return `${(costLine?.[currentFinalPrice] as number)?.toFixed(2).replace(".", ",")}€`;
};

export const labelMapper: Record<string, string> = {
	"one-shot": "oneShot-title",
	financial: "financial-title"
};
export const priceLabelMapper: Record<string, string> = {
	"one-shot": "oneShot",
	financial: "financial"
};

// Element
export const Costs = (props: CostsProps): JSX.Element => {
	const { costs, type } = props;
	const titleClass = labelMapper[type] || "recurring-title";

	return (
		<>
			<div className="cost">
				<div className="cost__details title">
					<span className={`cost__label medium ${titleClass}`}>{costs?.title}</span>

					{costs?.partialListPriceLabel || costs?.partialValidityLabel ? (
						<div className={`cost__recurring ${titleClass}`}>
							{costs?.partialListPriceLabel && (
								<span className="medium bold">{costs.partialListPriceLabel}</span>
							)}
							{costs?.partialValidityLabel && (
								<span className="medium cost__validitylabel">
									{costs.partialValidityLabel}
								</span>
							)}
						</div>
					) : null}
				</div>
				<span className={`cost__price medium ${priceLabelMapper[type] || "recurring"}`}>
					{showProductFinalPriceComponent(type, costs)}{" "}
				</span>
			</div>

			{costs?.costLines?.map((cost: RecapPageCostLine, idx: number) => (
				<div className="cost" key={"cost_" + idx}>
					<div className="cost__details">
						<span className="cost__label">{cost?.productName}</span>

						{cost?.listPriceLabel || cost?.validityLabel ? (
							<div className="cost__recurring">
								{cost?.listPriceLabel && (
									<span className="medium bold">{cost.listPriceLabel}</span>
								)}
								{cost?.validityLabel && (
									<span className="medium cost__validitylabel">
										{cost.validityLabel}
									</span>
								)}
							</div>
						) : null}
					</div>

					{!!cost && typeof cost.finalPrice === "number" && (
						<span className="cost__price medium">
							{showProductFinalPrice(type, cost)}
						</span>
					)}
				</div>
			))}
		</>
	);
};

export default Costs;
