import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { RecapPageDrawer } from "@sky-uk/ita-api-quotations-sdk";

export type DrawerState = {
	isDrawerOpen: boolean;
	drawerContent: RecapPageDrawer;
};

const initialState: DrawerState = {
	isDrawerOpen: false,
	drawerContent: {}
};

export const drawerSlice = createSlice({
	name: "drawer",
	initialState,
	reducers: {
		setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
			state.isDrawerOpen = action.payload;
		},
		setDrawerContent: (state, action: PayloadAction<RecapPageDrawer>) => {
			state.drawerContent = action.payload;
		}
	}
});

export const { setIsDrawerOpen, setDrawerContent } = drawerSlice.actions;

export const selectIsDrawerOpen = (state: RootState): boolean => state?.drawer.isDrawerOpen;
export const selectDrawerContent = (state: RootState): RecapPageDrawer =>
	state?.drawer.drawerContent;

export default drawerSlice.reducer;
