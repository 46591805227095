import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { QuotationAddress } from "@sky-uk/ita-api-quotations-sdk";
import { ErrorOtp, GetQuotationInfoQueryError } from "app/pages/recap/recap.types";
import { ErrorState } from "app/store/error/errorSlice";

export const maskChars = (string: string, mask: string, n: number): string => {
	return string.slice(0, string.length - n).replace(/./g, mask) + string.slice(-n);
};

export const addressCheck = (address: QuotationAddress | undefined): boolean | undefined => {
	return address && Object.keys(address).length > 0;
};

export const formatPhoneNumber = (num: string): string => {
	if (num.startsWith("+39")) {
		return num.substring(3);
	}
	if (num.startsWith("0039")) {
		return num.substring(4);
	}
	return num;
};

export const formatFiscalCode = (test: string): string => {
	return test.replace(
		/([A-Z0-9]{3})([A-Z0-9]{3})([A-Z0-9]{3})([A-Z0-9]{3})([A-Z0-9]{4})/,
		"$1 $2 $3 $4 $5"
	);
};

export const formatCreditCard = (test: string): string => {
	const maskedIban = maskChars(test, "*", 4);
	return maskedIban.replace(/([*]{4})([*]{4})([*]{4})([A-Z0-9]{4})/, "$1 $2 $3 $4");
};

export const formatIban = (test: string): string => {
	const maskedIban = maskChars(test, "*", 4);
	return maskedIban.replace(
		/([*]{2})([*]{2})([*]{1})([*]{5})([*]{5})([*]{3})([*]{3})([A-Z0-9_*]{3})([A-Z0-9]{2})/,
		"$1 $2 $3 $4 $5 $6 $7 $8 $9"
	);
};

export const scrollToEl = (selector: string, type: "cls" | "id"): void => {
	const el: HTMLElement | null =
		type === "cls" ? document.querySelector(selector) : document.getElementById(selector);

	/* istanbul ignore else */
	if (el) {
		window.scroll({
			top: el.offsetTop - 80,
			behavior: "smooth"
		});
	}
};

export const fakeElements = (className: string): void => {
	let htmlElements = "";
	for (let i = 0; i < 10; i++) {
		htmlElements += `<div class=${className}></div>`;
	}
	document.body.innerHTML += htmlElements;
};

// export function getCookie(cname: string) {
//     let cookie = {} as any;
//     document.cookie.split(';').forEach(function(el) {
//       let [ key, value ] = el.split('=');
//       cookie[key.trim()] = value;
//     })
//     return cookie[cname];
//   }

export const formatError = (
	error: FetchBaseQueryError | GetQuotationInfoQueryError | ErrorOtp | SerializedError
): { title: string; text: string } => {
	const errorData = "data" in error ? (error.data as ErrorState["data"]) : undefined;

	let title = "Il link alla pagina di riepilogo dell’ordine non è funzionante";
	let text = "Informa l'operatore se in linea, oppure contatta Sky.";

	if (errorData) {
		if ("title" in errorData) {
			title = errorData.title as string;
		}
		if ("text" in errorData) {
			text = errorData.text as string;
		}
		if ("code" in errorData && errorData.code === "CFx00000012") {
			title = "La pagina di inserimento codice OTP è già stata aperta";
			text =
				"Torna alla pagina di inserimento codice OTP e inserisci l'ultimo codice ricevuto per SMS. Se dovessi aver chiuso erroneamente la pagina OTP attendi qualche minuto e procedi alla riapertura attraverso il link che trovi nella mail";
		}
	}
	return { title, text };
};
