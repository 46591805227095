// Styles
import "./done.scss";

// Hooks & modules
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import useRedirectHome from "app/hooks/useRedirectHome";

// Components
import ReadyToSign from "app/components/ready-to-sign/ReadyToSign";

// Redux
import { setCurrentStep, selectSteps } from "app/store/metro/metroSlice";

// Utils
import { processDigitalData } from "utils/analytics";

// Types
import { QuotationView } from "@sky-uk/ita-api-quotations-sdk";
import { Step } from "app/components/metro/Metro";

export type DonePageProps = {
	dataView: QuotationView;
};

// Element
const DonePage = ({ dataView }: DonePageProps): JSX.Element => {
	useRedirectHome(dataView);

	const { generalConsentsSections, recapPageSummarySection, offerSection, privacySection } =
		dataView || /* istanbul ignore next: this is a fallback always true */ {};
	const dispatch = useAppDispatch();
	const steps = useAppSelector(selectSteps);
	const pathName = window.location.pathname;
	const currentStep = steps.find(({ pageName }) => pageName === pathName) as Step;

	useEffect(() => {
		processDigitalData({
			pageName: dataView?.thirdStepPageCorporateConfiguration?.pageCorporateLabel,
			pageType: "page",
			destinationURL: window.location.href,
			cleanUrl: window.location.origin + pathName
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataView]);

	useEffect(() => {
		steps.length && dispatch(setCurrentStep(currentStep));
	}, [dispatch, steps, currentStep]);

	return (
		<div className="done">
			{recapPageSummarySection && (
				<ReadyToSign
					summary={recapPageSummarySection}
					hasPrivacy={
						!!privacySection?.mandatoryConsentItems &&
						privacySection.mandatoryConsentItems.length > 0
					}
					hasOffers={!!offerSection?.costs?.length}
					onlyStrictConsents={
						!!generalConsentsSections && generalConsentsSections.length < 1
					}
				/>
			)}
		</div>
	);
};

export default DonePage;
