/* istanbul ignore file */
// Need to use the React-specific entry point to allow generating React hooks
import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError
} from "@reduxjs/toolkit/query/react";
import { AuthsOTP, AuthsOTPBody } from "app/pages/recap/recap.types";
import { globalConfig } from "configuration";

export type AuthorizeOTP = {
	id: string;
	destUrl: string;
	epn?: string;
	sourceId?: string;
	redirectBaseUrl?: string;
};

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	WebApi,
	extraOptions
) => {
	const configuration = globalConfig.get();
	const rawBaseQuery = fetchBaseQuery({
		baseUrl: configuration.REACT_APP_TM_API_URL,
		prepareHeaders: (headers) => {
			headers.set(
				configuration?.REACT_APP_HEADERS_X_SKY_CLIENT_NAME,
				configuration?.REACT_APP_HEADERS_X_SKY_CLIENT_VALUE
			);
			return headers;
		}
	});
	return rawBaseQuery(args, WebApi, extraOptions);
};
// Define a service using a base URL and expected endpoints
export const tokenManagerApi = createApi({
	reducerPath: "tokenManagerApi",
	baseQuery: dynamicBaseQuery,
	endpoints: (builder) => ({
		getAuthorizeOTP: builder.query<{ url: string }, AuthorizeOTP>({
			query: ({ id, destUrl, epn, sourceId, redirectBaseUrl }: AuthorizeOTP) => {
				if (!id || !destUrl || !epn || !sourceId || !redirectBaseUrl) {
					throw new Error(
						`Quotation one of those params are undefined: ${id}, ${destUrl}, ${epn}, ${sourceId}, ${redirectBaseUrl}`
					);
				}
				return {
					url: `${redirectBaseUrl}/authorizeOTP?id=${id}&destUrl=${destUrl}&epn=${epn}&sourceId=${sourceId}`,
					method: "GET",
					headers: { "Content-Type": "application/json" }
				};
			}
		}),
		authsOtp: builder.query<AuthsOTP, AuthsOTPBody>({
			query: (body: AuthsOTPBody) => ({
				url: "authsOTP",
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body
			})
		})
	})
});

export const { useGetAuthorizeOTPQuery, useAuthsOtpQuery } = tokenManagerApi;
export default tokenManagerApi;
