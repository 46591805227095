// Styles
import "./Error.scss";

// Hooks & modules
import { useAppSelector } from "app/store/hooks";

// Redux
import { selectError, ErrorState } from "app/store/error/errorSlice";

// Element
import IconSwitcher from "app/components/_shared/atoms/Icon/Icon";

// Element
const ErrorPage = (): JSX.Element => {
	const error: ErrorState = useAppSelector(selectError);
	const { data } = error;

	return (
		<div className="error-page">
			<div className="error-page-content">
				<IconSwitcher icon="Error" className="typ_icon" />
				<h1 data-testid="error_title" className="medium">
					{data.title}
				</h1>
				<p data-testid="error_description">{data.text}</p>
			</div>
		</div>
	);
};

export default ErrorPage;
