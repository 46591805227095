import "./Checkbox.scss";
import { ChangeEvent, useState } from "react";
import IconSwitcher from "../Icon/Icon";

export type CheckboxProps = {
	additionalClassName: string;
	checked: boolean;
	error?: boolean;
	disabled: boolean;
	onChange: (event?: ChangeEvent<HTMLInputElement>) => void;
	middleStatus?: boolean;
	children: React.ReactNode;
};

const Checkbox = ({
	additionalClassName,
	checked,
	disabled,
	onChange,
	error = false,
	middleStatus = false,
	children
}: CheckboxProps): JSX.Element => {
	const [isChecked, setIsChecked] = useState(checked);

	const styleModifier = `${disabled ? "disabled" : checked ? "checked" : error ? "error" : ""} ${
		middleStatus ? "middle" : ""
	}`;

	return (
		<>
			<label
				className="checkbox__wrp"
				onClick={() => {
					setIsChecked(!isChecked);
				}}
				data-testid="atoms-checkbox"
			>
				<input
					type="checkbox"
					className={`checkbox__hidden ${additionalClassName}`}
					defaultChecked={checked}
					disabled={disabled}
					onChange={onChange}
				/>
				<div className={`checkbox__inner ${styleModifier}`}>{children}</div>
				<div className={`checkbox ${styleModifier}`} tabIndex={0}>
					{(middleStatus || checked) && (
						<IconSwitcher
							icon={middleStatus ? "Minus" : "Tick"}
							className="checkbox__icon"
						/>
					)}
				</div>
			</label>
			{!!error && !checked && <p className="error_label">Questo campo è obbligatorio</p>}
		</>
	);
};

export default Checkbox;
