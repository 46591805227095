// Styles
import "./Drawer.scss";

// Modules & hooks
import { useRef } from "react";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import useOutsideClick from "app/hooks/useOutsideClick";
import useDisableScroll from "app/hooks/useDisableScroll";
import useViewport from "app/hooks/useViewport";

// Utils
import { motion, AnimatePresence } from "framer-motion";
import {
	drawerOverlayVariants,
	drawerFadeInRightVariants,
	drawerFadeInBottomVariants
} from "utils/core/motionConfig";

// Components
import IconSwitcher from "app/components/_shared/atoms/Icon/Icon";
import Accordion from "../accordion/Accordion";

// Redux
import { selectIsDrawerOpen, setIsDrawerOpen, selectDrawerContent } from "app/store/drawer/drawer";

// External Types
import { RecapPageDrawer, RecapPageDrawerRow } from "@sky-uk/ita-api-quotations-sdk";

// Element
const Drawer = (): JSX.Element => {
	const drawerRef = useRef(null);
	const dispatch = useAppDispatch();
	const isDrawerOpen = useAppSelector<boolean>(selectIsDrawerOpen);
	const drawerContent = useAppSelector<RecapPageDrawer>(selectDrawerContent);
	useDisableScroll(isDrawerOpen);

	const handleDrawerClose = (): void => {
		dispatch(setIsDrawerOpen(false));
	};

	useOutsideClick(drawerRef, () => {
		/* istanbul ignore else */
		if (isDrawerOpen) {
			handleDrawerClose();
		}
	});

	const { width } = useViewport();

	return (
		<>
			{drawerContent && (
				<AnimatePresence mode={"wait"}>
					{isDrawerOpen && (
						<motion.div
							key="drawerOverlay"
							// className={`drawer__overlay ${!isDrawerOpen && 'drawer__invisible'}`}
							className="drawer__overlay"
							variants={drawerOverlayVariants}
							initial="hidden"
							animate="visible"
							exit="hidden"
						>
							<motion.section
								key="drawer"
								ref={drawerRef}
								// className={`drawer__wrp ${!isDrawerOpen && 'drawer__invisible'}`}
								className="drawer__wrp"
								variants={
									width >= 768
										? drawerFadeInRightVariants
										: drawerFadeInBottomVariants
								}
							>
								<div className="drawer__nav">
									<h4 className="drawer__nav--title">
										{drawerContent?.drawerTitle}
									</h4>
									<div className="drawer__control" onClick={handleDrawerClose}>
										<IconSwitcher
											icon="CloseCircle"
											className="drawer__control--icon"
										/>
										<span
											data-testid="close-button"
											className="drawer__control--text blue"
										>
											Chiudi
										</span>
									</div>
								</div>
								<div>
									{drawerContent?.drawerRows?.map(
										(row: RecapPageDrawerRow, index: number) => (
											<Accordion
												key={index}
												renderCards={true}
												index={index}
												title={row.accordionTitle}
												contentDrawer={
													row.drawerItems
														? row.drawerItems
														: /* istanbul ignore next: this is a fallback always true */ []
												}
											/>
										)
									)}
								</div>
							</motion.section>
						</motion.div>
					)}
				</AnimatePresence>
			)}
		</>
	);
};

export default Drawer;
