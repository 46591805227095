// Styles
import "./Consents.scss";

// Components
import Acceptance from "app/components/acceptance/Acceptance";

// External Types
import { RecapPageGeneralConditionsSection } from "@sky-uk/ita-api-quotations-sdk/model/recapPageGeneralConditionsSection";

// Types
export type ContentsProps = {
	currentIndex: number;
	startIndex: number;
	isFreezed: boolean;
	iconButton?: boolean;
	navigateOnLastCta?: boolean;
	finalAcceptanceIndex?: number;
	consentIndex?: number;
} & RecapPageGeneralConditionsSection;

// Element
const Consents = ({
	title,
	items,
	currentIndex,
	isFreezed,
	iconButton,
	startIndex,
	navigateOnLastCta,
	finalAcceptanceIndex,
	consentIndex
}: ContentsProps): JSX.Element => {
	const disabled = !isFreezed && currentIndex < startIndex + 1 ? "disabled" : "";

	return (
		<div className="consents__wrp">
			<h2 id={`consents_title_${consentIndex}`} className={`consents__title ${disabled}`}>
				{title}
			</h2>
			{items?.map((acceptance, idx) => (
				<Acceptance
					key={startIndex + idx + 1}
					currentIndex={currentIndex}
					index={startIndex + idx + 1}
					isFreezed={isFreezed}
					consentIndex={consentIndex}
					iconButton={iconButton}
					isLastAcceptance={idx + 1 === items.length}
					navigateOnLastCta={
						navigateOnLastCta && startIndex + idx + 1 === finalAcceptanceIndex
					}
					{...acceptance}
				/>
			))}
		</div>
	);
};

export default Consents;
