// Hooks
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Types
import { QuotationView } from "@sky-uk/ita-api-quotations-sdk";

const useRedirectHome = (dataView?: QuotationView): void => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!dataView) {
			navigate("/");
		}
	}, [dataView, navigate]);
};

export default useRedirectHome;
