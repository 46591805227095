import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Step } from "app/components/metro/Metro";
import { RootState } from "app/store/store";

export type MetroState = {
	currentStep: Step;
	steps: Step[];
};

const initialState: MetroState = {
	currentStep: {} as Step,
	steps: [] as Step[]
};

export const metroSlice = createSlice({
	name: "metro",
	initialState,
	reducers: {
		setCurrentStep: (state, action: PayloadAction<Step>) => {
			state.currentStep = action.payload;
		},
		setSteps: (state, action: PayloadAction<Step[]>) => {
			state.steps = action.payload;
		}
	}
});

export const { setCurrentStep } = metroSlice.actions;
export const { setSteps } = metroSlice.actions;

export const selectCurrentStep = (state: RootState): Step => state.metro.currentStep;
export const selectSteps = (state: RootState): Step[] => state.metro.steps;

export default metroSlice.reducer;
