// Style
import "./Offer.scss";

// Components
import CostsRecap from "../costs-recap/CostsRecap";
import Costs, { OneShotOrRecurringOrFinancial } from "../costs/Costs";

// External Types
import { RecapPageOfferCostsSection } from "@sky-uk/ita-api-quotations-sdk/model/recapPageOfferCostsSection";
import { RecapPageComponentLine } from "@sky-uk/ita-api-quotations-sdk";

// Utils
const typeMapper: Record<string, OneShotOrRecurringOrFinancial> = {
	RECURRENT: "recurring",
	FINANCIAL: "financial",
	ONE_TIME: "one-shot"
};

// Element
export const Offer = (offer: RecapPageOfferCostsSection): JSX.Element => {
	const typePayment = offer.paymentType
		? typeMapper[offer.paymentType]
		: /* istanbul ignore next: this is a fallback always true */ "one-shot";

	return (
		<div className="offer__distance-top">
			<div className="offer__heading">
				<span className="offer__title">{offer?.costTypeHeading}</span>
			</div>
			<span className="offer__divider" />
			{offer.components?.map((components: RecapPageComponentLine, idx: number) => (
				<Costs key={"offer__divider_" + idx} costs={components} type={typePayment} />
			))}

			<CostsRecap offerCosts={offer} type={typePayment}></CostsRecap>
		</div>
	);
};

export default Offer;
