import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import { store } from "./app/store/store";

import moment from "moment";
import "moment/locale/it";
import { globalConfig, globalConfigUrl } from "configuration";
moment.locale("it");

const renderMainApp = (isError?: boolean): void => {
	const container = document.getElementById("root");
	const root = createRoot(container!);
	const element = isError ? (
		<p
			style={{
				color: "red",
				textAlign: "center",
				padding: "0 10%",
				marginTop: "20%",
				fontSize: "30px"
			}}
		>
			Error while fetching global config, the App wil not be rendered. (This is NOT a React
			error.)
		</p>
	) : (
		<React.StrictMode>
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</React.StrictMode>
	);
	root.render(element);
};

fetch(globalConfigUrl, { cache: "no-store" })
	.then((res) => res.json())
	.then((response) => {
		globalConfig.set(response);
		renderMainApp();
	})
	.catch(() => {
		renderMainApp(true);
	});
