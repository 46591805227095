/* istanbul ignore file */
// config file
import { Step } from "app/components/metro/Metro";
import { TargetAndTransition, Variants } from "framer-motion";

export const defaultEasing = [0.87, 0, 0.13, 1];
export const contentEasing = [0.6, -0.05, 0.01, 0.99];

export const staggerContent = {
	animate: { transition: { staggerChildren: 0.18, delayChildren: 0.4 } }
};

export const drawerFadeInRightVariants = {
	hidden: {
		opacity: 0,
		right: "-100%",
		bottom: "inherit",
		transition: { duration: 0.6, ease: defaultEasing }
	},
	visible: {
		opacity: 1,
		right: "0%",
		bottom: "inherit",
		transition: { duration: 0.8, ease: defaultEasing }
	}
};

export const drawerFadeInBottomVariants = {
	hidden: {
		opacity: 0,
		bottom: "-100%",
		right: "inherit",
		transition: { duration: 0.6, ease: defaultEasing }
	},
	visible: {
		opacity: 1,
		bottom: "0%",
		right: "inherit",
		transition: { duration: 0.8, ease: defaultEasing }
	}
};

export const drawerOverlayVariants = {
	hidden: { opacity: 0, transition: { duration: 0.2, delay: 0.4 } },
	visible: { opacity: 1, transition: { duration: 0.2 } }
};

export const drawerFadeInUpVariants = {
	initial: {
		y: 20,
		opacity: 0,
		transition: { duration: 0.6, ease: contentEasing }
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: { duration: 0.8, ease: contentEasing }
	}
};

export const metroVariants: Variants = {
	visible: ({ currentStep, steps }: { currentStep: Step; steps: Step[] }): TargetAndTransition =>
		currentStep?.index !== steps[0].index
			? {
					height: "auto",
					opacity: 1,
					transition: {
						height: { duration: 0.5, type: "spring" },
						opacity: { duration: 0.7, delay: 0.2, type: "spring" }
					}
				}
			: { height: 0, opacity: 0 }
};
