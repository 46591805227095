import "./Button.scss";
import { ReactNode } from "react";
import IconSwitcher, { IconSwitcherProps } from "../Icon/Icon";

export type ButtonProps = {
	additionalClassName?: string;
	children: ReactNode;
	disabled?: boolean;
	icon?: IconSwitcherProps["icon"];
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onMouseDown?: () => void;
	onMouseUp?: () => void;
	onMouseOut?: () => void;
	onTouchStart?: () => void;
	onTouchEnd?: () => void;
};

const Button = ({
	children,
	additionalClassName = "",
	icon,
	disabled = false,
	onClick,
	onMouseDown,
	onMouseUp,
	onMouseOut,
	onTouchStart,
	onTouchEnd
}: ButtonProps): JSX.Element => {
	const btnProps = {
		disabled,
		onClick,
		onMouseDown,
		onMouseUp,
		onMouseOut,
		onTouchStart,
		onTouchEnd
	};

	return (
		<button
			{...btnProps}
			type="button"
			className={`button ${additionalClassName}`}
			data-testid="atoms-button"
		>
			{children && <p>{children}</p>}
			{!!icon && <IconSwitcher className="button_icon" icon={icon} />}
		</button>
	);
};

export default Button;
