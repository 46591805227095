// Style
import "./PersonalData.scss";

// Types
import { RecapPagePersonalDataSection } from "@sky-uk/ita-api-quotations-sdk";

// Utils
import { formatFiscalCode, formatPhoneNumber, addressCheck } from "utils";
import moment from "moment";

// Components
import IconSwitcher from "app/components/_shared/atoms/Icon/Icon";

// Helper
const getSummary = (heading: string, subheading?: string): JSX.Element => {
	return (
		<section className="summary__wrp flex-wrp">
			{/* why? */}
			{/* <section className={`summary__wrp ${hasIcon && "flex-wrp"}`}> */}

			<IconSwitcher className="summary__info" icon="InfoCircle" />
			<div className="summary__contentWrp">
				<p
					className="summary__title medium"
					dangerouslySetInnerHTML={{ __html: heading }}
				/>
				{subheading?.trim() && (
					<p
						className="summary__description"
						dangerouslySetInnerHTML={{ __html: subheading }}
					/>
				)}
			</div>

			{/* in case of no icon, but where is the usage? hasIcon: boolean */}
			{/* <>
					<p
						className="summary__title medium"
						dangerouslySetInnerHTML={{ __html: heading }}
					/>
					{!!subheading && subheading.trim() !== "" && (
						<p
							className="summary__description"
							dangerouslySetInnerHTML={{ __html: subheading }}
						/>
					)}
				</> */}
		</section>
	);
};

// Element
const PersonalData = ({
	summaryHeading,
	summarySubheading,
	customer
}: RecapPagePersonalDataSection): JSX.Element => {
	return (
		<section className="personal-data">
			{!!summaryHeading && getSummary(summaryHeading, summarySubheading)}

			{customer?.e2eContractId && (
				<>
					<div className="personal-data__row contactId">
						<div className="personal-data__col contactId">
							<span className="medium sky-body">Codice Cliente: </span>
							<span>{customer?.e2eContractId}</span>
						</div>
					</div>
					<span className="personal-data__divider" />
				</>
			)}
			<div className="personal-data__row">
				<div className="personal-data__col">
					<h3 className="medium sky-body">Dati intestatario</h3>

					<div className="personal-data__col--el">
						<p className="normal">Nome e Cognome</p>
						<p>
							{customer?.firstName} {customer?.lastName}
						</p>
					</div>
					{!customer?.isLegalEntity ? (
						customer &&
						customer.fiscalCode && (
							<div className="personal-data__col--el">
								<p className="normal">Codice Fiscale</p>
								<p>{formatFiscalCode(customer.fiscalCode)}</p>
							</div>
						)
					) : (
						<>
							<div className="personal-data__col--el">
								<p className="normal">Ragione Sociale</p>
								<p>{customer.businessName}</p>
							</div>
							{customer && customer.fiscalCode && (
								<div className="personal-data__col--el">
									<p className="normal">Codice Fiscale Amministratore</p>
									<p>{formatFiscalCode(customer.fiscalCode)}</p>
								</div>
							)}
							{customer && customer.legalFiscalCode && (
								<div className="personal-data__col--el">
									<p className="normal">Codice Fiscale Società</p>
									<p>{formatFiscalCode(customer.legalFiscalCode)}</p>
								</div>
							)}
							<div className="personal-data__col--el">
								<p className="normal">Partita IVA</p>
								<p>{customer.vatNumber}</p>
							</div>
						</>
					)}
				</div>

				{!!customer?.idCard && (
					<div className="personal-data__col">
						<h3 className="medium sky-body">Documento</h3>
						{!!customer?.idCard?.idCardType && (
							<div className="personal-data__col--el">
								<p className="normal">Tipologia</p>
								<p>{customer?.idCard?.idCardType}</p>
							</div>
						)}
						{!!customer?.idCard?.code && (
							<div className="personal-data__col--el">
								<p className="normal">Numero</p>
								<p>{customer?.idCard?.code}</p>
							</div>
						)}
						{!!customer?.idCard?.expiryDate && (
							<div className="personal-data__col--el">
								<p className="normal">Data di scadenza</p>
								<p>{moment(customer?.idCard?.expiryDate).format("L")}</p>
							</div>
						)}
					</div>
				)}
				<div className="personal-data__col">
					<h3 className="medium sky-body">Informazioni di contatto</h3>

					{customer && customer.phoneNumber && (
						<div className="personal-data__col--el">
							<p className="normal">Cellulare</p>
							<p>{formatPhoneNumber(customer.phoneNumber)}</p>
						</div>
					)}
					{customer && customer.alternativePhoneNumber && (
						<div className="personal-data__col--el">
							<p className="normal">Cellulare alternativo</p>
							<p>{formatPhoneNumber(customer.alternativePhoneNumber)}</p>
						</div>
					)}
					{customer && customer.emailAddress && (
						<div className="personal-data__col--el">
							<p className="normal">Indirizzo email</p>
							<p>{customer.emailAddress}</p>
						</div>
					)}
					{customer && customer.certifiedEmailAddress && (
						<div className="personal-data__col--el">
							<p className="normal">Indirizzo PEC</p>
							<p>{customer.certifiedEmailAddress}</p>
						</div>
					)}
				</div>
			</div>
			<span className="personal-data__divider" />
			<div className="personal-data__row">
				{addressCheck(customer?.installationAddress) && (
					<div className="personal-data__col">
						<h3 className="medium sky-body">Indirizzo d'installazione</h3>
						<div className="personal-data__col--el">
							<p className="normal">Via e numero civico</p>
							<p>
								{customer?.installationAddress?.street},{" "}
								{customer?.installationAddress?.streetNumber}
							</p>
						</div>
						<div className="personal-data__col--el">
							<p className="normal">CAP</p>
							<p>{customer?.installationAddress?.zipCode}</p>
						</div>
						<div className="personal-data__col--el">
							<p className="normal">Città, Provincia</p>
							<p>
								{customer?.installationAddress?.city} (
								{customer?.installationAddress?.state})
							</p>
						</div>
						{customer?.installationAddress?.at && (
							<div className="personal-data__col--el">
								<p className="normal">Citofono/Info aggiuntive</p>
								<p>{customer?.installationAddress?.at}</p>
							</div>
						)}
					</div>
				)}
				{addressCheck(customer?.shippingAddress) && (
					<div className="personal-data__col">
						<h3 className="medium sky-body">Indirizzo di consegna</h3>

						<div className="personal-data__col--el">
							<p className="normal">Via e numero civico</p>
							<p>
								{customer?.shippingAddress?.street},{" "}
								{customer?.shippingAddress?.streetNumber}
							</p>
						</div>
						<div className="personal-data__col--el">
							<p className="normal">CAP</p>
							<p>{customer?.shippingAddress?.zipCode}</p>
						</div>
						<div className="personal-data__col--el">
							<p className="normal">Città, Provincia</p>
							<p>
								{customer?.shippingAddress?.city} (
								{customer?.shippingAddress?.state})
							</p>
						</div>
						{customer?.shippingAddress?.at && (
							<div className="personal-data__col--el">
								<p className="normal">Citofono/Info aggiuntive</p>
								<p>{customer?.shippingAddress?.at}</p>
							</div>
						)}
					</div>
				)}
				{addressCheck(customer?.billingAddress) && (
					<div className="personal-data__col">
						<h3 className="medium sky-body">Indirizzo di fatturazione</h3>

						<div className="personal-data__col--el">
							<p className="normal">Via e numero civico</p>
							<p>
								{customer?.billingAddress?.street},{" "}
								{customer?.billingAddress?.streetNumber}
							</p>
						</div>
						<div className="personal-data__col--el">
							<p className="normal">CAP</p>
							<p>{customer?.billingAddress?.zipCode}</p>
						</div>
						<div className="personal-data__col--el">
							<p className="normal">Città, Provincia</p>
							<p>
								{customer?.billingAddress?.city} ({customer?.billingAddress?.state})
							</p>
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default PersonalData;
