// Style
import "./Panel.scss";

//Redux
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectDrawerContent, setIsDrawerOpen } from "../../store/drawer/drawer";

// Types
import { OfferSection } from "app/pages/recap/recap.types";

// External Types
import { RecapPageDrawer } from "@sky-uk/ita-api-quotations-sdk";

// Element
const Panel = ({
	title,
	subtitle,
	__className,
	children,
	isOfferSection,
	paymentMethodsSection
}: OfferSection): JSX.Element => {
	const dispatch = useAppDispatch();
	const handleDetailsClick = (): void => {
		dispatch(setIsDrawerOpen(true));
	};
	const drawerContent = useAppSelector<RecapPageDrawer>(selectDrawerContent);

	return (
		<div
			className={`${
				paymentMethodsSection ? "panel-paymentMethodsSection" : "panel"
			} ${__className}`}
		>
			{title && (
				<div className="cta">
					<div>
						<p className="cta-title medium sky-section-title">{title}</p>
						<p className="cta-subtitle">{subtitle}</p>
					</div>
					{!!isOfferSection && !!drawerContent?.drawerRows?.length && (
						<span
							data-testid="guarda-dettaglio-link"
							className="cta-detail"
							onClick={handleDetailsClick}
						>
							Guarda nel dettaglio
						</span>
					)}
				</div>
			)}
			<div className="card">{children}</div>
		</div>
	);
};

export default Panel;
