// Styles
import "./Accordion.scss";

// Modules & hooks
import React, { useEffect, useState } from "react";

// Components
import IconSwitcher from "../_shared/atoms/Icon/Icon";

// External Types
import {
	RecapPageDrawerProductItem,
	RecapPageOfferAdditionalDetailItem
} from "@sky-uk/ita-api-quotations-sdk";

// Types
export type AccordionProps = {
	title?: string;
	contentDrawer?: RecapPageDrawerProductItem[];
	contentDetailOffer?: RecapPageOfferAdditionalDetailItem[];
	index: number;
	renderCards?: boolean;
	isOffersAccordion?: boolean;
};

// Element
const Accordion = ({
	title,
	contentDetailOffer,
	contentDrawer,
	index,
	renderCards,
	isOffersAccordion
}: AccordionProps): JSX.Element => {
	const [isOpen, setOpen] = useState<boolean>(false);

	useEffect(() => {
		if (isOffersAccordion) {
			setOpen(true);
		}
	}, [isOffersAccordion, index]);

	const toggleAccordion = (): void => {
		setOpen(!isOpen);
	};

	const renderCard = (): JSX.Element => {
		return (
			<div className="drawer__content">
				{contentDrawer?.map((drawerBox: RecapPageDrawerProductItem, idx: number) => (
					<div key={"drawer__box_" + idx} className="drawer__box">
						<div className="drawer__box--wrp">
							<div className="drawer__box--txt">
								<p className="drawer__box--title">{drawerBox?.name}</p>
							</div>
							<img
								className="drawer__box--img"
								src={drawerBox.image}
								alt="Drawer box package"
							/>
						</div>
						{drawerBox?.descriptions && (
							<ul className="drawer__list">
								{drawerBox.descriptions.map(
									(boxDescription: React.ReactNode, idx: number) => (
										<li
											key={"drawer__list_" + idx}
											className="drawer__list--item"
										>
											<IconSwitcher
												icon="Tick"
												fill="#4D4D4D"
												className="drawer__list--icon"
											/>
											<span>{boxDescription}</span>
										</li>
									)
								)}
							</ul>
						)}
					</div>
				))}
			</div>
		);
	};

	const renderDetailOffer = (): JSX.Element => {
		return (
			<div className="offerDetails__content">
				{contentDetailOffer?.map((detail, idx) => (
					<div key={"offerDetails_" + idx} className="offerDetails">
						<p className="offerDetails__title medium">{detail.title}</p>
						<div className="offerDetails__wrp">
							{detail.firstLine && (
								<p
									className="offerDetails__firstline"
									dangerouslySetInnerHTML={{ __html: detail.firstLine }}
								/>
							)}
							{detail.secondLine && (
								<p
									className="offerDetails__secondline normal"
									dangerouslySetInnerHTML={{ __html: detail.secondLine }}
								/>
							)}
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<div
			className={`${
				renderCards ? "accordion-container-cards" : "accordion-container-detailoffer"
			}`}
		>
			<div
				data-testid="accordion-header-test-id"
				className={`accordion-header ${renderCards ? "accordion-header-padding" : ""}`}
				onClick={toggleAccordion}
			>
				<span className={isOffersAccordion ? "accordion-title-offers" : "accordion-title"}>
					{title}
				</span>
				<IconSwitcher
					icon="ChevronDown"
					fill={"#4D4D4D"}
					className={`accordion-icon ${isOpen ? "accordion-rotate-svg" : ""}`}
				/>
			</div>
			{isOpen && (
				<div className="accordion-content">
					{renderCards ? renderCard() : renderDetailOffer()}
				</div>
			)}
		</div>
	);
};

export default Accordion;
