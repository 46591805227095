/* istanbul ignore file */

export type DynamicConfig = {
	REACT_APP_CNT_API_URL: string;
	REACT_APP_TM_API_URL: string;
	REACT_APP_OTP_URL: string;
	REACT_APP_ADOBE_LAUNCH_URL: string;
	REACT_APP_HEADERS_X_SKY_CLIENT_NAME: string;
	REACT_APP_HEADERS_X_SKY_CLIENT_VALUE: string;
};

class GlobalConfig {
	config!: DynamicConfig;

	get(): DynamicConfig {
		return this.config;
	}

	set(value: DynamicConfig): void {
		this.config = value;
	}
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "config.json";
