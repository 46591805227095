// Style
import "./Payment.scss";

// Types
import { RecapPagePaymentMethodsSection } from "@sky-uk/ita-api-quotations-sdk";

// Utils
import { frequencyType, paymentType, recurringType } from "./PaymentMapping";
import { formatCreditCard, formatFiscalCode, formatIban } from "utils";

// Helper
export const getPaymentMethodLabel = (
	recurringType: string,
	trailingLabel: string,
	contractType?: string
): string => {
	return recurringType === "ONE_SHOT" && contractType === "GLASS"
		? "costi che ti verranno addebitati nelle prossime 24 ore"
		: "costi da sostenere " + trailingLabel;
};

// Element
const Payment = ({ paymentMethods }: RecapPagePaymentMethodsSection): JSX.Element => {
	return (
		<div className="payment">
			{paymentMethods?.map((method, key) => {
				return (
					<div
						key={"payment_" + key}
						className={
							key === paymentMethods.length - 1
								? "payment-option"
								: "payment-option-border payment-option"
						}
					>
						{paymentMethods.length > 1 && method?.recurringType && (
							<p className="sky-capital medium">
								{method &&
									getPaymentMethodLabel(
										method.recurringType,
										recurringType[method.recurringType],
										method.contractType
									)}
							</p>
						)}
						<div className="payment-option-wrapper">
							{method.paymentType && (
								<img
									src={`/images/${paymentType[method.paymentType].icon}.svg`}
									alt=""
								/>
							)}
							<div className="payment-option-content">
								{method.paymentType && (
									<div className="payment-option-content-section">
										<p className="medium">Tipologia</p>
										<p>{paymentType[method.paymentType].type}</p>
									</div>
								)}
								{method.recurringType && method.recurringType === "YEARLY" && (
									<div className="payment-option-content-section">
										<p className="medium">Frequenza</p>
										<p>{frequencyType[method.recurringType]}</p>
									</div>
								)}
								{method.iban && (
									<div className="payment-option-content-section">
										<p className="medium">Numero IBAN</p>
										<p>{formatIban(method.iban)}</p>
									</div>
								)}
								{method.creditCard && (
									<>
										{method.creditCard?.number && (
											<div className="payment-option-content-section">
												<p className="medium">Numero Carta</p>
												<p>{formatCreditCard(method.creditCard?.number)}</p>
											</div>
										)}
										{method.creditCard?.expiryMonth &&
											method.creditCard?.expiryYear && (
												<div className="payment-option-content-section">
													<p className="medium">Scadenza</p>
													<p>
														{method.creditCard?.expiryMonth}/
														{method.creditCard?.expiryYear}
													</p>
												</div>
											)}
									</>
								)}
								{method.accountHolder && (
									<>
										{method.accountHolder.fullName && (
											<div className="payment-option-content-section">
												<p className="medium">Intestato a</p>
												<p>{method.accountHolder.fullName}</p>
											</div>
										)}
										{method.accountHolder.fiscalCode && !method.creditCard && (
											<div className="payment-option-content-section">
												<p className="medium">Codice Fiscale</p>
												<p>
													{formatFiscalCode(
														method.accountHolder.fiscalCode
													)}
												</p>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Payment;
