// Styles
import "./Metro.scss";

// Redux
import { selectSteps } from "app/store/metro/metroSlice";
import { useAppSelector } from "app/store/hooks";

// Utils
import { stepLabels } from "utils/core/constants";

// Types
export type Step = {
	index: number;
	pageName: string;
};

const getClasses = (currentStepIndex: number, stepIndex: number, classes: string[]): string => {
	if (currentStepIndex === stepIndex) {
		return classes[0];
	}
	if (currentStepIndex > stepIndex) {
		return classes[1];
	}
	return "";
};

// Element
const Metro = ({ currentStep }: { currentStep: Step }): JSX.Element => {
	const steps = useAppSelector(selectSteps);

	return (
		<div className="stepper">
			{steps.map((step: Step, key: number) => {
				return (
					<div
						className={`step-wrapper ${getClasses(currentStep?.index, step.index, [
							"selected current",
							"selected"
						])}`}
						key={key}
					>
						{key > 0 && (
							<div className="step-line">
								<div className={"step-line-content"}></div>
							</div>
						)}
						<div className="step">
							<div
								className={`step-icon sky-body-small ${getClasses(
									currentStep?.index,
									step.index,
									["selected current", "selected"]
								)}`}
							>
								<p className={currentStep?.index > step.index ? "checked" : ""}>
									{currentStep?.index > step.index ? "L" : step.index}
								</p>
							</div>
							<p
								className={`step-label medium ${getClasses(
									currentStep?.index,
									step.index,
									["selected current", "selected"]
								)}`}
							>
								{stepLabels[key]}
							</p>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Metro;
