import { useEffect } from "react";

const useScript = (url?: string, isHead?: boolean): void => {
	useEffect(() => {
		let script: HTMLScriptElement | undefined = undefined;
		const scriptArea = isHead ? document.head : document.body;
		/* istanbul ignore else */
		if (url) {
			script = document.createElement("script");
			script.src = url;
			script.async = true;
			scriptArea.appendChild(script);
		}

		return (): void => {
			/* istanbul ignore else */
			if (script) {
				scriptArea.removeChild(script);
			}
		};
	}, [url, isHead]);
};

export default useScript;
